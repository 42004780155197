<template>
	<div
		class="modal-ad-container"
		:style="{ display: isHidden ? 'none' : 'block' }"
	>
		<!-- Ad Placement -->
		<div
			:class="`plc733362 modal-ad`"
			ref="adButlerModal"
		></div>

		<div
			class="ad-modal-overlay"
			@click="onClose"
		/>
	</div>
</template>

<script setup>
const adButlerModal = ref();
const $emit = defineEmits(["close"]);
const onClose = () => {
	$emit("close", "closing");
};

const isHidden = ref(true);

const adId = 733362;
onMounted(() => {
	// Check if AdButler is already defined to avoid re-inserting the script
	if (process.client) {
		if (!window.AdButler) {
			const script = document.createElement("script");
			script.async = true;
			script.type = "text/javascript";
			script.src = "https://servedbyadbutler.com/app.js";
			adButlerModal.value.parentElement.prepend(script);

			initializeAdButler();
		} else {
			// Directly initialize AdButler if the script is already present
			initializeAdButler();
		}
	}
});

const initializeAdButler = () => {
	const script = document.createElement("script");
	const adButlerScript = `
      var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
      var abkw = window.abkw || '';
      var plc${adId} = window.plc${adId} || 0;
      (function () {
          var divs = document.querySelectorAll(".plc${adId}:not([id])");
          var div = divs[divs.length - 1];
          div.setAttribute("id", "placement_${adId}_" + plc${adId});
          AdButler.ads.push({ handler: function (opt) { AdButler.register(181458, ${adId}, [0, 0], 'placement_${adId}_' + opt.place, opt); }, opt: { place: plc${adId}++, keywords: abkw, domain: 'servedbyadbutler.com', click: 'CLICK_MACRO_PLACEHOLDER' } });
      })();
    `;

	script.innerHTML = adButlerScript;
	adButlerModal.value.parentNode.appendChild(script);
};

watch(adButlerModal, async (newContent) => {
	if (process.client && newContent) {
		await setTimeout(() => {
			const adButlerClose = document.querySelector(".adblutler-modal-close");
			const adButlerLinks = document.querySelectorAll(".modal-ad a");

			if (!adButlerClose) {
				$emit("close", "closing");
				return;
			}

			isHidden.value = false;
			const adButlerAdButton = document.querySelector(
				".information-subscribe-button"
			);
			adButlerAdButton.addEventListener("click", () => {
				$emit("close", "closing");
			});
			adButlerClose.onclick = () => {
				$emit("close", "closing");
			};

			[...adButlerLinks].forEach((link) => {
				link.setAttribute("target", "_blank");
			});
		}, 500);
	}
});
</script>

<style lang="scss">
.adblutler-modal-close {
	cursor: pointer;
	margin-bottom: 16px;
	margin-left: auto;
	display: block;
	max-width: 40px;
	position: relative;
	text-align: right;
	@include breakpoint(lg) {
		margin-bottom: 24px;
	}
}

.ad-modal-overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 0;
}
.modal-ad {
	min-width: 320px;
	min-height: 200px;
	max-width: 500px;
	padding: 20px 24px 64px;
	overflow: hidden;
	border-radius: 12px;
	background-color: white;
	position: relative;
	z-index: 1;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border: 3px solid $neutral-200;
	@include breakpoint(lg) {
		border: none;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
		padding: 20px 32px 40px;
	}
	&::before {
		content: "";
		background-color: #1e3a8a;
		height: 24px;
		width: 100%;
		display: block;
		left: 0;
		right: 0;
		bottom: 0;
		position: absolute;
	}
	&::after {
		content: "";
		background-image: url("/mstile-144x144.png");
		height: 32px;
		width: 32px;
		display: block;
		background-size: contain;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 8px;
		outline: 4px solid #1e3a8a;
		border-radius: 32px;
		margin: auto;
	}
	iframe {
		height: 100%;
		width: 100%;
	}

	.mb-5 {
		margin-bottom: 32px;
	}

	img {
		width: 100%;
		border-radius: 12px;
	}
}
.modal-title {
	text-align: center;
	font-size: 20px;
	margin-bottom: 23px;
	font-weight: 700;
	@include breakpoint(lg) {
		font-size: 28px;
		margin-bottom: 24px;
	}
}

.modal-ad-container {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 9999;
	display: flex;
	padding: 64px 16px;
	background-color: rgba($color: #fff, $alpha: 0.65);
	overflow: auto;

	.information-subscribe-button {
		padding-top: 12px;
		padding-bottom: 12px;
		font-size: 18px;
	}
}
</style>

import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import posts_lists_xqGPFPKC7T from "/opt/build/repo/plugins/posts-lists.js";
import sentry_client_shVUlIjFLk from "/opt/build/repo/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  posts_lists_xqGPFPKC7T,
  sentry_client_shVUlIjFLk
]
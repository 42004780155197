import axios from "axios";

export const fetchPostsList = async ($url, $page = 1, $category) => {

	let query = {
		per_page: 20,
		page: $page,
		_embed: true
	};

	if ($category) {
		query = { ...query, categories: $category };
	}
	const response = await axios.get($url, {
		params: query
	});
	return response;
};
export const fetchTagPostsList = async ($url, $slug) => {
	try {
		console.log(
			{ $slug },
			`
        query NewQuery {
            tags(where: {slug: ${$slug}}) {
                nodes {
                    ${BASIC_QUERY}
                    posts(where: {tag: ${$slug}}){
                        ${POST_NODE_QUERY}
                    }
                }
            }
        }`
		);
		const response = await $fetch($url, {
			method: "post",
			body: {
				query: `
                    query NewQuery {
                        tags(where: {slug: "${$slug}"}) {
                            nodes {
                                ${BASIC_QUERY}
                                posts(where: {tag: "${$slug}"}){
                                    ${POST_NODE_QUERY}
                                }
                            }
                        }
                    }`
			}
		});
		return response;
	} catch (e) {
		console.log("ERROR ON TAG POSTS LIST FETCH", e);
	}
};
export const makeFetchGetRequest = async ($url) => {
	try {
		const response = await $fetch($url);
		return response;
	} catch (e) {
		console.log("ERROR ON POSTS LIST FETCH", e);
	}
};

export const fetchBeyondWords = async ($payload) => {
	const { url, headers } = $payload;

	try {
		const response = await $fetch(url, {
			headers
		});

		return response;
	} catch (e) {
		console.log("ERROR ON BEYOND WORDS FETCH", e);
	}
};

export const postShapeFormatter = (og_post, page) => {
	const { id, title, slug, categories, content, _embedded } = og_post;

	const image =
		_embedded["wp:featuredmedia"] && _embedded["wp:featuredmedia"][0]
			? _embedded["wp:featuredmedia"][0].source_url
			: null;
	return {
		id,
		title,
		slug,
		categories,
		image,
		page,
		content
	};
};

export const getPostRatings = (postId, ratings_url, ratings_key) => {
	return $fetch(
		`${ratings_url}/ratings/${postId}/get_rating?wid=timothysykes_com`,
		{
			headers: {
				"x-mprtgs-token": ratings_key
			}
		}
	);
};
<template>
	<div class="progress-container">
		<div
			class="progress-bar"
			:style="{ width: progressBarWidth }"
		/>
	</div>
</template>

<script setup>
import throttle from "lodash/throttle";
const progressBarWidth = ref("0%");

const updateProgressBar = () => {
	const scrollPosition = window.scrollY;
	const footer = document.querySelector(".footer");
	const totalHeight =
		document.body.scrollHeight - footer.scrollHeight * 3 - window.innerHeight;
	const progress = (scrollPosition / totalHeight) * 100;
	progressBarWidth.value = `${progress <= 100 ? progress : 100}%`;
};

const throttledUpdateProgressBar = throttle(updateProgressBar, 100);

onMounted(() => {
	window.addEventListener("scroll", throttledUpdateProgressBar, {
		passive: true
	});
});

onBeforeUnmount(() => {
	window.removeEventListener("scroll", throttledUpdateProgressBar, {
		passive: true
	});
});
</script>

<style scoped>
.progress-container {
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	height: 10px;
	background-color: #fff;
	z-index: 999;
}

.progress-bar {
	height: 100%;
	background-color: #fac515;
	transition: width 100ms ease-in-out;
}
</style>

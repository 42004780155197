<template>
	<div class="page-loading">
		<img
			src="/images/loading.svg"
			class="loading-svg"
		/>
	</div>
</template>

<style lang="scss">
.page-loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	background-color: rgba($color: #fff, $alpha: 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
}
</style>

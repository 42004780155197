const useAdbutlerChecker = () => {

    const showAds = ref(false);
    const showAdButlerBanner = ref(false);

    const hideBanner = () => {
        localStorage.setItem("showAdButlerBanner", "false");
        showAdButlerBanner.value = false;
    };

    onMounted(() => {
        if (process.client) {

            const shouldShowAdButlerBanner = localStorage.getItem("showAdButlerBanner");

            if (window.adsAreWithUs) {
                showAds.value = true;
            }

            if (
                shouldShowAdButlerBanner == null ||
                JSON.parse(shouldShowAdButlerBanner) === true
            ) {
                localStorage.setItem("showAdButlerBanner", "true");
                showAdButlerBanner.value = true;
            } else {
                localStorage.setItem("showAdButlerBanner", "false");

                return;
            }
        }
    });

    return { showAds, showAdButlerBanner, hideBanner };
};


export default useAdbutlerChecker;
<template>
	<aside
		ref="sidebar"
		class="content-sidebar"
		:class="{
			'has-adbutler-banner': showAdButlerBanner
		}"
	>
		<template
			v-for="items in sidebar_data"
			:key="items.ID"
		>
			<NuxtLink
				:to="items.url"
				class="main-hublink"
			>
				<span>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clip-path="url(#clip0_749_11555)">
							<path
								d="M24 0L17.3043 1.01151L19.2533 2.96053L15.3824 6.83141L13.3495 4.92681L8.20066 10.2039L6.32566 8.32895L0.382401 14.1513L2.15132 15.9548L6.30592 11.8816L8.22039 13.7961L13.4285 8.46217L15.4391 10.347L21.0395 4.74671L22.9885 6.69572L24 0ZM20.2105 10.1053V24H22.7368V10.1053H20.2105ZM15.1579 12.6316V24H17.6842V12.6316H15.1579ZM10.1053 15.1579V24H12.6316V15.1579H10.1053ZM5.05263 17.6842V24H7.57895V17.6842H5.05263ZM0 20.2105V24H2.52632V20.2105H0Z"
								fill="white"
							/>
						</g>
						<defs>
							<clipPath id="clip0_749_11555">
								<rect
									width="24"
									height="24"
									fill="white"
								/>
							</clipPath>
						</defs>
					</svg>
				</span>
				{{ items.title }}
			</NuxtLink>

			<template v-if="items.children && items.children.length > 0">
				<div
					v-for="(block, i) in items.children"
					:key="i"
					:ref="blockRef[i]"
					class="links-block"
					:class="{
						active:
							checkLinkBlock(block.children) ||
							isDesktop ||
							(items.url === $route.fullPath && i === 0) ||
							items.children.length === 1
					}"
				>
					<template v-if="block.children">
						<button
							class="hublink"
							@click="toggleBlock"
						>
							{{ block.title }}

							<img
								src="/images/expand.svg"
								alt=""
							/>
						</button>
						<div
							v-if="block.children && block.children.length > 0"
							class="links-container"
						>
							<NuxtLink
								v-for="link in block.children"
								:key="link.Object_slug"
								:class="`link ${$route.fullPath === link.url && 'active'}`"
								:to="link.url"
							>
								{{ link.title }}
							</NuxtLink>
						</div>
					</template>

					<NuxtLink
						v-else
						:key="block.url"
						:class="`link ${$route.fullPath === block.url && 'active'}`"
						:to="block.url"
					>
						{{ block.title }}
					</NuxtLink>
				</div>
			</template>
		</template>
	</aside>

	<button
		class="left-fab"
		@click="toggle"
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="#3b82f6"
			enable-background="new 0 0 24 24"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
		>
			<g>
				<rect
					fill="none"
					height="24"
					width="24"
				/>
			</g>
			<g>
				<g />
				<g>
					<path
						d="M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z"
					/>
					<g>
						<path
							d="M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.7,0-3.24,0.29-4.5,0.83v1.66 C14.13,10.85,15.7,10.5,17.5,10.5z"
						/>
						<path
							d="M13,12.49v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24 C15.8,11.66,14.26,11.96,13,12.49z"
						/>
						<path
							d="M17.5,14.33c-1.7,0-3.24,0.29-4.5,0.83v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26v-1.52 C19.21,14.41,18.36,14.33,17.5,14.33z"
						/>
					</g>
				</g>
			</g>
		</svg>
		{{ !showOrHide ? "Open menu" : "Hide menu" }}
	</button>
</template>

<script setup>
import throttle from "lodash/throttle";

const showOrHide = ref(false);
const isDesktop = ref(false);
const blockRef = ref([]);
const $route = useRoute();
const { showAdButlerBanner } = useAdbutlerChecker();
let sidebar_data = reactive(store.contentSidebar.data);

defineProps({
	data: {
		type: Object,
		required: true,
		default: () => ({})
	}
});

const sidebar = ref();

const checkLinkBlock = (arr) => {
	const flattened = Array.isArray(arr) ? arr.map((item) => item.url) : [];
	return flattened.includes($route.fullPath);
};

const toggleBlock = (e) => {
	const parent = e.target.parentElement;

	parent.classList.contains("active")
		? parent.classList.remove("active")
		: parent.classList.add("active");
};

const onAdbutlerBanerClose = () => {
	adjustHeight();
};

const adjustHeight = async (extra = 0, isMobile) => {
	if (!sidebar || !sidebar.value) {
		return null;
	}

	const toc_sidebar = sidebar.value;

	if (isMobile) {
		toc_sidebar.style.height = "100vh";

		return;
	}
	const header = document.querySelector("header.header");

	if (header) {
		const cookie_consent = document.querySelector(".cookie-consent");

		const cookie_consent_extra =
			cookie_consent && cookie_consent.getBoundingClientRect().bottom > 0 ? 80 : 0;

		const showAdButlerBannerBool =
			localStorage.getItem("showAdButlerBanner") === "true";

		let extra_height = extra;

		let banner_height = 0;
		if (showAdButlerBanner) {
			await setTimeout(() => {
				let adbutler_banner_wrapper = document.querySelector(
					".ad-buttler-ribbon-outer-wrapper"
				);

				if (adbutler_banner_wrapper) {
					banner_height = adbutler_banner_wrapper.getBoundingClientRect().height;

					extra_height +=
						window.scrollY === 0 && showAdButlerBannerBool ? banner_height : 0;

					const adbutler_banner_close = document.querySelector(
						".adbutler-ribbon-button-close"
					);

					adbutler_banner_close.addEventListener("click", onAdbutlerBanerClose);
				}

				toc_sidebar.style.height = `calc(100vh - ${
					header.getBoundingClientRect().height + cookie_consent_extra + extra_height
				}px)`;
			}, 150);
		} else {
			toc_sidebar.style.height = `calc(100vh - ${
				header.getBoundingClientRect().height + cookie_consent_extra + extra_height
			}px)`;
		}
	}
};

const sidebarRepositioner = () => {
	if (sidebar.value && window.innerWidth >= 2220) {
		const blog_content = document
			.querySelector(".blog-container")
			.getBoundingClientRect();

		sidebar.value.style.left = `${blog_content.left - 340}px`;
	} else if (sidebar.value && window.innerWidth < 2220) {
		sidebar.value.style.left = `0`;
	}
};

const throttledSidebarRepositioner = throttle(sidebarRepositioner, 250);

const sortSidebarData = (fullPath) => {
	const newData = sidebar_data;

	const findItemAndParent = (structure, parent = null) => {
		for (let i = 0; i < structure.length; i++) {
			const item = structure[i];
			if (item && typeof item === "object" && "url" in item) {
				if (item.url === fullPath) {
					return { item, parent };
				}
			}
			if (item && "children" in item && Array.isArray(item.children)) {
				const result = findItemAndParent(item.children, item);
				if (result) {
					return result;
				}
			}
		}
		return null;
	};

	const result = findItemAndParent(newData[0].children);

	if (result) {
		const { item, parent } = result;

		if (item !== null && parent !== null) {
			const parentIndex = newData[0].children.indexOf(parent);
			if (parentIndex !== 0) {
				newData[0].children.splice(parentIndex, 1);
				newData[0].children.splice(0, 0, parent);
			}
		}
	}

	sidebar_data = newData;
};

onMounted(async () => {
	if (process.client) {
		if (sidebar && sidebar.value && window.innerWidth > 2220) {
			await setTimeout(() => sidebarRepositioner(), 10);

			window.addEventListener("resize", throttledSidebarRepositioner, {
				passive: true
			});
		}

		if (sidebar && sidebar.value && window.innerWidth > 1280) {
			await adjustHeight();
			window.addEventListener(
				"scroll",
				() => {
					const scrollY = window.scrollY;

					if (scrollY > 10) {
						adjustHeight(10);
					} else {
						adjustHeight();
					}
				},
				{ passive: true }
			);
		} else if (sidebar && sidebar.value) {
			const slideMessage =
				document.querySelector(".slide-message") ||
				document.querySelector(".slide-message-desktop");

			if (slideMessage) {
				const button = document.querySelector(".left-fab");
				button.classList.add("site-has-message");
			}
		}

		if (window.innerWidth > 1280) {
			isDesktop.value = true;
		}

		await sortSidebarData($route.fullPath);
	}
});

watch(
	() => $route.path,
	(newPath, oldPath) => {
		if (process.client && newPath !== oldPath && showOrHide.value) {
			toggle({ manualOff: true });
		}
		if (process.client && newPath !== oldPath) {
			sortSidebarData(newPath);
			sidebar.value.scrollTop = 0;
		}
	}
);

watch(store, async (newState) => {
	if (!store.contentSidebar.slug) {
		store.setContentSidebarTitle(newState.contentSidebar.data[0].object_slug);
	} else if (
		newState.contentSidebar.data[0].object_slug !== store.contentSidebar.slug
	) {
		store.toggleContentSidebarVisibility(false);
		store.setContentSidebarTitle(newState.contentSidebar.data[0].object_slug);
		await setTimeout(() => {
			store.toggleContentSidebarVisibility(true);
		}, 1);
	}

	if (
		newState.isContentDoneLoading &&
		!newState.isLoading &&
		process.client &&
		window.innerWidth > 2220
	) {
		sidebarRepositioner();
	}
});

onBeforeUnmount(() => {
	window.removeEventListener("resize", throttledSidebarRepositioner);
});

const toggle = ({ manualOff = false }) => {
	if (manualOff) {
		showOrHide.value = false;
		sidebar.value.classList.remove("active");
		return;
	}

	showOrHide.value = !showOrHide.value;
	sidebar.value.classList.contains("active")
		? sidebar.value.classList.remove("active")
		: sidebar.value.classList.add("active");
};
</script>

<style lang="scss" scoped>
@import "../assets/utils.scss";

.left-fab {
	position: fixed;
	right: 20px;
	bottom: 36px;
	z-index: 999;
	background-color: #fff;
	border: 2px solid $primary-500;
	border-radius: 8px;
	color: $primary-500;
	font-size: 16px;
	font-weight: 400;
	padding: 8px 12px;
	display: flex;
	align-items: center;
	-moz-column-gap: 12px;
	column-gap: 12px;

	&.site-has-message {
		bottom: 88px;
	}

	@include breakpoint(xl) {
		display: none;
	}
}

.links-block .links-container {
	height: 0;
	overflow: hidden;
	transition: all 300ms ease-in-out;
}

.links-block.active .links-container {
	height: auto;

	img {
		transform: rotate(180deg);
	}
}

.links-block img {
	pointer-events: none;
}
.links-block.active img {
	transform: rotate(180deg);
}

.content-sidebar {
	background-color: white;
	position: fixed;
	left: 0;
	margin: auto;
	height: 100vh;
	bottom: 0;
	padding: 24px 16px;
	transition: all 100ms ease-in;
	width: 100%;
	z-index: 999;
	padding-bottom: 124px;

	overflow-y: auto;
	left: -100%;

	&.active {
		left: 0;
		height: 100%;
	}

	@include breakpoint(xl) {
		z-index: 9;

		padding-bottom: 124px;
		&.active {
			height: auto;
		}
	}
	@include breakpoint(xl) {
		left: 0;
		display: block;
		width: 268px;
		overflow-y: hidden;

		&:hover {
			overflow-y: auto;
		}
	}
}

.content-sidebar::-webkit-scrollbar {
	width: 2px;
}

.content-sidebar::-webkit-scrollbar-thumb {
	background: #1e3a8a;
	border-radius: 8px;
}

.content-sidebar::-webkit-scrollbar-track {
	background: #e2e8f0;
}

.main-hublink {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 8px 0;
	text-align: left;
	margin-bottom: 8px;
	padding-right: 4px;

	span {
		background-color: #22c55e;
		height: 32px;
		width: 32px;
		min-width: 32px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			height: 16px;
			width: 16px;
		}
	}

	color: $neutral-900;
	font-family: Rubik;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 130%;
	/* 18.2px */
	letter-spacing: 0.14px;
	text-transform: uppercase;
}

.hublink {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	padding: 8px 0;
	text-align: left;
	margin-bottom: 8px;
	color: $neutral-900;
	font-family: Rubik;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 130%;
	letter-spacing: 0.14px;
	text-transform: uppercase;
	width: 100%;

	img {
		width: 24px;
		height: 24px;
	}
}

@mixin activeLink {
	color: $primary-700;
	background-color: $primary-50;
	border-left-color: $primary-500;
}

.link {
	width: 235px;
	padding: 8px 10px;
	color: $neutral-800;
	font-family: Rubik;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 135%;
	display: block;
	border-left: 2px solid #e2e8f0;
	width: 100%;
	@include breakpoint(lg) {
		width: auto;
	}
	&.router-link-active {
		@include activeLink;
	}

	&:hover {
		@include activeLink;
	}
}
</style>

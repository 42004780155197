<template>
	<ErrorNotFound v-if="props.error.statusCode === 404" />
	<ServerError v-else />
	<Head>
		<Title>Error | Timothy Sykes</Title>
		<Meta
			name="description"
			content="Since 2009, StocksToTrade has helped new and seasoned traders make consistently profitable trades.  Try us today for only $7.00"
		/>
	</Head>
</template>

<script setup>
import ErrorNotFound from "~/components/ErrorNotFound";
import ServerError from "~/components/ServerError";

const props = defineProps({
	error: {
		type: Object,
		required: true,
		default: () => ({})
	}
});

onMounted(() => {
	if (props.error.statusCode === 404) {
		history.pushState(null, null, "/404/");
	}
});
</script>

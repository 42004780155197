<template>
	<div
		v-for="(menu, index) in props.navigation"
		:key="menu.ID"
		class="nav-link-block"
		:class="{
			active: activeIndex === index
		}"
		@click="toggleActive(index)"
	>
		<NuxtLink
			:to="menu.url"
			:class="{
				'nav-link': true,
				'main-nav-link': !menu.url.includes('#')
			}"
		>
			<span class="uppercase">
				{{ menu.post_title }}
				<img
					v-if="menu.children"
					src="/images/expand.svg"
					alt=""
				/>
			</span>
		</NuxtLink>

		<div
			v-if="menu.children"
			class="nav-submenu-container"
			:class="{
				active: activeIndex === index
			}"
		>
			<div class="xl:container mx-auto lg:grid grid-cols-4">
				<div
					v-for="(submenu, i) in menu.children"
					:key="i"
					class="nav-menu-block"
				>
					<Heading
						tag="h4"
						:text="submenu.post_title"
						class="nav-submenu-title"
					/>

					<NuxtLink
						v-for="item in submenu.children"
						:key="item.title"
						class="nav-submenu-link"
						:to="item.url"
						target="_self"
					>
						{{ item.title }}
					</NuxtLink>
				</div>
			</div>
		</div>
	</div>
	<div
		class="nav-link-block"
		:class="{ active: isActive }"
		@click="setActive($event)"
	>
		<div class="nav-link">
			<span class="uppercase">
				Sign In
				<img
					src="/images/expand.svg"
					alt=""
				/>
			</span>
		</div>
		<div
			class="nav-submenu-container"
			:class="{ active: isActive }"
		>
			<div class="xl:container mx-auto lg:grid grid-cols-4">
				<div class="nav-menu-block">
					<NuxtLink
						class="nav-submenu-link"
						href="https://alerts.timothysykes.com"
						target="_blank"
					>
						Student Portal
					</NuxtLink>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, nextTick } from "vue";
const props = defineProps({
	navigation: {
		type: Array,
		required: true
	}
});
const activeIndex = ref(null);
const isActive = ref(false);
const toggleActive = (index) => {
	if (activeIndex.value === index || activeIndex.value === -1) {
		activeIndex.value = null;
	} else {
		activeIndex.value = index;
	}
};

const setActive = (event) => {
	event.preventDefault();
	event.stopPropagation();
	nextTick(() => {
		isActive.value = !isActive.value;
	});
};

onMounted(() => {});
</script>

<style lang="scss" scoped>
@import "../assets/utils.scss";

.mobile-menu {
	background-color: white;
	border-radius: 8px;
	padding: 8px;

	&.close {
		background-color: transparent;
	}
}

.header {
	background-color: $primary-900;

	.container {
		padding: 10px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	padding: 0 16px;
}

.nav-link-container {
	column-gap: 24px;
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px transparent;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 transparent;
	}
}

.blob.red {
	background: #ff5252;
	box-shadow: 0 0 0 0 #ff5252;
	animation: pulse-red 2s infinite;
}

.blob {
	background: #000;
	border-radius: 50%;
	box-shadow: 0 0 0 0 #000;
	margin: 14px 8px 15px 12px;
	height: 10px;
	width: 10px;
	transform: scale(1);
	animation: pulse-black 2s infinite;
}

.nav-link-block:hover .nav-link {
	color: $primary-900;
}

.nav-link-block .nav-submenu-container.active {
	display: block;
}

.nav-submenu-container {
	display: none;

	.container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-auto-flow: dense;
	}
}

.nav-menu-block {
	display: flex;
	flex-direction: column;
	column-gap: 16px;
}

.nav-submenu-container {
	position: absolute;
	width: 100%;
	left: 0;
	top: 59px;
	background: $primary-500;
	padding: 20px 0;
}

.nav-submenu-title {
	color: white;
	font-size: 18px;
	font-family: Rubik;
	margin-bottom: 12px;
}

.nav-submenu-link {
	font-size: 16px;
	font-weight: 400;
	padding: 8px 0;
	color: $primary-50;
	font-family: Rubik;

	&:hover {
		text-decoration: underline;
	}
}

.mobile-nav-link-container {
	.nav-link-block {
		padding: 12px 0;
		border-bottom: 1px solid $neutral-300;

		&:last-child {
			border-bottom: none;
		}
		&.active {
			.uppercase {
				img {
					width: 24px;
					transform: rotate(180deg);
				}
			}
		}
	}

	.nav-link {
		color: $neutral-800;

		.uppercase {
			display: flex;
			align-items: center;
			justify-content: space-between;
			img {
				transition: all 200ms ease-in-out;
				width: 24px;
			}
		}
	}

	.mobile-live-btn {
		display: flex;
		gap: 16px;
		padding: 16px 0;
		max-width: 210px;
		border-top: 6px solid #eab308;
	}

	.nav-submenu-container {
		display: none;
		position: relative;
		top: 0;
		padding: 12px 0;
		background-color: transparent;
	}

	.nav-submenu-title {
		color: $neutral-800;
		font-weight: 600;
	}

	.nav-submenu-link {
		font-size: 16px;
		font-weight: 400;
		padding: 8px 16px;
		line-height: 1.25;
		color: $neutral-800;
		display: inline-block;
		font-family: Rubik;
		border-left: 4px solid $primary-700;
		&.active {
			color: $primary-700;
			background-color: white;
			border-left-color: $primary-500;
		}
	}

	.nav-menu-block {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.nav-link {
	color: #fff;
	font-family: Fira Sans;
	font-size: 24px;
	font-style: normal;
	height: 38px;
	font-weight: 400;
	line-height: 150%;
	/* 36px */
	letter-spacing: 1.44px;
	position: relative;

	&.live {
		border-radius: 8px;
		background: $error-700;
		display: flex;
		padding: 6px 16px;
		align-items: center;
		font-weight: 600;
		gap: 8px;
		line-height: 1;
		font-size: 16px;
		color: white;
	}
}
</style>

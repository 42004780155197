<template>
	<div :class="classes">
		<slot />
	</div>
</template>

<script setup lang="ts">
type Props = {
	class?: string;
};

const props = defineProps<Props>();

const classes = `row ${props.class || ""}`;
</script>

<style lang="scss" scoped>
@import "../assets/utils.scss";

.row {
	padding: 40px 0;
	display: flex;
	justify-content: center;

	@include breakpoint(lg) {
		padding: 80px 0;
	}

	&.pt-0 {
		padding-top: 0;
	}

	&.pb-0 {
		padding-bottom: 0 !important;
	}
}
</style>

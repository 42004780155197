import FourOhFours from "../utils/redirect/NotFound.json";

export default defineNuxtRouteMiddleware((to, from) => {
	const ignoreFileExtensions = [".webp", ".ico", ".jpg", ".png", ".webmanifest"];

	const pageTypes = [
		"blog-slug",
		"blog-author-tim-sykes",
		"blog-page-page",
		"category-slug",
		"category-slug-page-page",
		"widgetpage"
	];

	if (pageTypes.includes(to.name)) {
		if (to.name === "category-slug") {
			const parsed_category = [
				"breaking-news",
				"case-studies",
				"charity-work",
				"guides",
				"infographics",
				"lessons-from-students",
				"mentor-update",
				"patterns-to-short",
				"patterns-to-watch",
				"stock-basics",
				"news",
				"watchlists",
				"penny-stocks",
				"press",
				"promoters",
				"penny-stock-scandals",
				"short-selling",
				"success-stories",
				"testimonials",
				"timalerts",
				"trading-lessons",
				"trading-psychology",
				"trading-recap",
				"trading-tips",
				"videos",
				"text-watchlists",
				""
			];

			if (
				to.name === "category-slug" &&
				to.params.slug.length > 2 &&
				!parsed_category.includes(to.params.slug[2])
			)
				return navigateTo("/404/", { redirectCode: 404 });
			if (
				to.name === "category-slug" &&
				to.params.slug.length > 3 &&
				!parsed_category.includes(to.params.slug[3])
			)
				return navigateTo("/404/", { redirectCode: 404 });
		}
		if (to.name === "widgetpage" && to.params.widgetpage.length > 2)
			return navigateTo("/404/", { redirectCode: 404 });
	}
	if (FourOhFours.includes(to.fullPath) && process.server) {
		return navigateTo("/404/", { redirectCode: 404 });
	}

	if (to.name === "blog-slug" && to.params.slug.length > 2) {
		return navigateTo("/404/", { redirectCode: 404 });
	}

	if (!to.path.endsWith("/") && !to.path.endsWith(ignoreFileExtensions)) {
		return navigateTo(to.path + "/", { redirectCode: 301 });
	}
});

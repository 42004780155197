export const BASE_COMMENT = `parentId
dateGmt
date
content
databaseId
author {
  node {
    id
    name
    url
    avatar {
      url
      size
    }
  }
}
commentId
parentDatabaseId`;

export const TAGS_QUERY = `tags {
  nodes {
    name
    tagId
    slug
  }
}`;

export const COMMENTS_QUERY = `
commentCount
comments(first: 99) {
  nodes {
    ${BASE_COMMENT}
    replies {
      nodes {
        ${BASE_COMMENT}
        replies {
          nodes {
            ${BASE_COMMENT}
            replies {
              nodes {
                ${BASE_COMMENT}
              }
            }
          }
        }
      }
    }
  }
}
`;
export const BY_QUERY = `
beyondwords {
  projectId
  podcastId
}
`;
export const SEO_QUERY = `
saswpSchema {
  json_ld
}
`;

export const POST_PAGE_QUERY = `
title
postId
slug
content
date
dateGmt
categories {
  nodes {
    name
    id
    categoryId
    slug
    uri
    posts(first : 3) {
      nodes {
        title
        uri
        excerpt
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
}
featuredImage {
  node {
    uri
    sourceUrl
  }
}
author {
  node {
    firstName
    description
    name
    lastName
    avatar {
      url
      size
    }
  }
}
`;

export const POST_AUTHOR_QUERY = `
author {
  node {
    firstName
    description
    name
    lastName
    avatar {
      url
      size
    }
  }
}`

export const BLOG_POSTS_LIST_QUERY = `
posts {
  nodes {
    title
    uri
    featuredImage {
      node {
        altText
        sourceUrl
      }
    }
  }
}
`;
export const POST_CATEGORIES_QUERY = `
categories {
  nodes {
    posts(first : 4) {
      nodes {
        title
        uri
        excerpt
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
}
`;

export const NAV_MENU_QUERY = `
menu(id: "28771", idType: DATABASE_ID) {
  name
  slug
  id
  menuItems {
    nodes {
      id
      uri
      label
      childItems {
        nodes {
          label
          uri
          childItems {
            nodes {
              label
              uri
            }
          }
        }
      }
    }
  }
}
`;
export const BASIC_QUERY = `
link
id
slug
name
`;

export const POST_NODE_QUERY = `
nodes {
  slug
  title
  content
  categories {
    nodes {
      name
      slug
    }
  }
  featuredImage {
    node {
      sourceUrl
    }
  }
}
`;
export const PAGE_NODE_QUERY = `
nodes {
  slug
  title
  content
  featuredImage {
    node {
      sourceUrl
    }
  }
}
`;

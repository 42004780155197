<template>
	<div class="disclaimer-container">
		<Container class="mx-auto">
			<div class="disclaimer">
				<div class="column">
					<p class="column-item">
						* Results are not typical and will vary from person to person. Making
						money trading stocks takes time, dedication, and hard work. There are
						inherent risks involved with investing in the stock market, including the
						loss of your investment. Past performance in the market is not indicative
						of future results. Any investment is at your own risk.
						<nuxt-link
							class="disclaimer-styling"
							to="/terms-of-service/"
							target="_blank"
						>
							See Terms of Service here
						</nuxt-link>
					</p>
					<p class="column-item">
						The available research on day trading suggests that most active traders
						lose money. Fees and overtrading are major contributors to these losses.
					</p>

					<p class="column-item">
						A 2000 study called
						<nuxt-link
							class="disclaimer-styling"
							to="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=219228"
							target="_blank"
						>
							“Trading is Hazardous to Your Wealth: The Common Stock Investment
							Performance of Individual Investors”
						</nuxt-link>
						evaluated 66,465 U.S. households that held stocks from 1991 to 1996. The
						households that traded most averaged an 11.4% annual return during a
						period where the overall market gained 17.9%. These lower returns were
						attributed to overconfidence.
					</p>

					<p class="column-item">
						A 2014 paper (revised 2019) titled
						<nuxt-link
							class="disclaimer-styling"
							to="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=2535636"
							target="_blank"
						>
							“Learning Fast or Slow?”
						</nuxt-link>
						analyzed the complete transaction history of the Taiwan Stock Exchange
						between 1992 and 2006. It looked at the ongoing performance of day traders
						in this sample, and found that 97% of day traders can expect to lose money
						from trading, and more than 90% of all day trading volume can be traced to
						investors who predictably lose money. Additionally, it tied the behavior
						of gamblers and drivers who get more speeding tickets to overtrading, and
						cited studies showing that legalized gambling has an inverse effect on
						trading volume.
					</p>
					<p class="column-item">
						A 2019 research study (revised 2020) called
						<nuxt-link
							class="disclaimer-styling"
							to="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3423101"
							target="_blank"
						>
							“Day Trading for a Living?”
						</nuxt-link>
						observed 19,646 Brazilian futures contract traders who started day trading
						from 2013 to 2015, and recorded two years of their trading activity. The
						study authors found that 97% of traders with more than 300 days actively
						trading lost money, and only 1.1% earned more than the Brazilian minimum
						wage ($16 USD per day). They hypothesized that the greater returns shown
						in previous studies did not differentiate between frequent day traders and
						those who traded rarely, and that more frequent trading activity decreases
						the chance of profitability.
					</p>
					<p class="column-item">
						These studies show the wide variance of the available data on day trading
						profitability.
						<b>
							One thing that seems clear from the research is that most day traders
							lose money
						</b>
						.
					</p>
				</div>
				<div class="column">
					<p class="column-item">
						Millionaire Media 66 W Flagler St. Ste. 900 Miami, FL 33130 United States
						(888) 878-3621 This is for information purposes only as Millionaire Media
						LLC nor Timothy Sykes is registered as a securities broker-dealer or an
						investment adviser. No information herein is intended as securities
						brokerage, investment, tax, accounting or legal advice, as an offer or
						solicitation of an offer to sell or buy, or as an endorsement,
						recommendation or sponsorship of any company, security or fund.
						Millionaire Media LLC and Timothy Sykes cannot and does not assess, verify
						or guarantee the adequacy, accuracy or completeness of any information,
						the suitability or profitability of any particular investment, or the
						potential value of any investment or informational source. The reader
						bears responsibility for his/her own investment research and decisions,
						should seek the advice of a qualified securities professional before
						making any investment, and investigate and fully understand any and all
						risks before investing. Millionaire Media LLC and Timothy Sykes in no way
						warrants the solvency, financial condition, or investment advisability of
						any of the securities mentioned in communications or websites. In
						addition, Millionaire Media LLC and Timothy Sykes accepts no liability
						whatsoever for any direct or consequential loss arising from any use of
						this information. This information is not intended to be used as the sole
						basis of any investment decision, nor should it be construed as advice
						designed to meet the investment needs of any particular investor. Past
						performance is not necessarily indicative of future returns.
					</p>
					<p class="column-item">
						<b>Citations for Disclaimer</b>
					</p>
					<p class="column-item">
						Barber, Brad M. and Odean, Terrance, Trading is Hazardous to Your Wealth:
						The Common Stock Investment Performance of Individual Investors. Available
						at SSRN:
						<nuxt-link
							class="disclaimer-styling"
							to="https://ssrn.com/abstract=219228"
							target="_blank"
						>
							“Day Trading for a Living?”
						</nuxt-link>
					</p>
					<p class="column-item">
						Barber, Brad M. and Lee, Yi-Tsung and Liu, Yu-Jane and Odean, Terrance and
						Zhang, Ke, Learning Fast or Slow? (May 28, 2019). Forthcoming: Review of
						Asset Pricing Studies, Available at SSRN:
						<nuxt-link
							class="disclaimer-styling"
							to="https://ssrn.com/abstract=2535636"
							target="_blank"
						>
							“https://ssrn.com/abstract=2535636”
						</nuxt-link>
					</p>
					<p class="column-item">
						Chague, Fernando and De-Losso, Rodrigo and Giovannetti, Bruno, Day Trading
						for a Living? (June 11, 2020). Available at SSRN:
						<nuxt-link
							class="disclaimer-styling"
							to="https://ssrn.com/abstract=2535636"
							target="_blank"
						>
							“https://ssrn.com/abstract=3423101”
						</nuxt-link>
					</p>
				</div>
			</div>
			<div class="menu-bottom-container">
				<ul class="menu-bottom">
					<li class="menu-item">
						<nuxt-link to="/terms-of-service/">
							Terms of Service and User Agreement
						</nuxt-link>
					</li>
					<li class="menu-item">
						<nuxt-link to="/privacy-policy/">Privacy Policy (Statement)</nuxt-link>
					</li>
					<li class="menu-item">
						<nuxt-link to="/earnings-claims-disclosure/">
							Earnings Disclosure
						</nuxt-link>
					</li>
					<li class="menu-item">
						<nuxt-link to="/member-terms/">Member Agreement</nuxt-link>
					</li>
					<li class="menu-item">
						<nuxt-link to="/code-of-conduct/">Code of Conduct</nuxt-link>
					</li>
					<li class="menu-item">
						<nuxt-link to="/editorial-guidelines/">Editorial Guidelines</nuxt-link>
					</li>
					<li class="menu-item">
						<nuxt-link to="/affiliate-disclosure/">Affiliate Disclosure</nuxt-link>
					</li>
					<li class="menu-item">
						<nuxt-link to="/dmca-takedown-policy/">DMCA Takedown Policy</nuxt-link>
					</li>
					<li class="menu-item">
						<nuxt-link to="/artificial-intelligence-disclosure/">
							Artificial Intelligence Disclosure
						</nuxt-link>
					</li>
					<li class="menu-item">
						<nuxt-link to="/cookie-policy/">Cookie Policy</nuxt-link>
					</li>
					<li class="menu-item">
						<nuxt-link to="/sitemap/">Sitemap</nuxt-link>
					</li>
				</ul>
			</div>
		</Container>
	</div>
</template>
<style lang="scss" scoped>
.disclaimer-container {
	padding: 64px 0;
	background: #0a0e1d;

	position: relative;
	z-index: 99;
	&.hasSwipe {
		margin-bottom: 72px;
	}
}

.disclaimer-inner {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin: auto;
}

.disclaimer {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 52px;
}

.column {
	width: 615;
	flex: 1 0 0;
	color: var(--neutrals-slate-500, #64748b);
	font-family: Rubik;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 24px */
}

p.column-item {
	font-family: Rubik;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	b {
		font-weight: 400;
	}
}

p.column-item:not(:first-child) {
	margin-top: 20px;
}

.menu-bottom-container {
	margin-top: 49px;
	border-top: 1px solid #64748b;
	padding-top: 32px;
}

.menu-bottom {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
}

.menu-bottom li {
	color: var(--neutrals-slate-500, #64748b);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	/* 22.4px */
}

@media (max-width: 992px) {
	.disclaimer-container {
		max-width: 100%;
		padding: 40px 0;
	}

	.disclaimer-inner {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.disclaimer {
		width: 100%;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}

	.column {
		width: 100%;
		color: var(--neutrals-slate-500, #64748b);
		font-family: Rubik;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}

	.column-item {
		margin-top: 10px;
	}

	.menu-bottom-container {
		margin-top: 20px;
		border-top: 1px solid #64748b;
		padding-top: 20px;
	}

	.menu-bottom {
		display: flex;
		width: auto;
		flex-wrap: wrap;
		gap: 16px;
		justify-content: unset;
	}

	.author-card-container {
		width: 100%;
	}

	.menu-item {
		font-family: Rubik;
	}

	.menu-bottom li {
		text-align: left;
		display: inline-block;
	}
}

.disclaimer-styling {
	font-weight: bold;
	text-decoration: underline;
}
</style>

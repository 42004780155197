import postsListDict from "~/utils/posts/posts-dictionary.json";
import categories from "~/utils/categories-data.json";
import tags from "~/utils/posts/tags.json";

export default defineNuxtPlugin(async nuxtApp => {

    return {
        provide: {
            postsListDict,
            tags,
            categories
        }
    }
});
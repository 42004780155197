<template>
	<button
		v-if="props.type === 'smallest'"
		class="btn smallest clear"
		@click="props.onClick"
		v-html="props.label ?? '[Click Here]'"
	/>
	<NuxtLink
		v-else-if="props.type === 'link' || props.to"
		:to="props.to"
		class="btn btn-link"
		:class="{
			primary: appearance === 'primary',
			secondary: appearance === 'secondary'
		}"
		@click="
			() => {
				store.toggleLoading();
			}
		"
	>
		{{ props.label ?? "[Click Here]" }}
	</NuxtLink>
	<button
		v-else
		class="btn"
		:class="{
			primary: appearance === 'primary',
			secondary: appearance === 'secondary',
			finished: props.isFinished
		}"
		@click="props.onClick"
		v-html="props.label ?? '[Click Here]'"
	/>
</template>

<script setup>
const props = defineProps({
	label: {
		type: String,
		default: ""
	},
	to: {
		type: String,
		default: ""
	},
	appearance: {
		type: String,
		default: "primary"
	},
	type: {
		type: String,
		default: ""
	},
	isFinished: {
		type: Boolean,
		default: false
	},
	onClick: {
		type: Function,
		default: () => {}
	}
});
</script>

<style lang="scss" scoped>
@import "../assets/utils.scss";

.btn-link {
	display: block;
	margin: auto;
	width: fit-content;
}
.btn {
	display: flex;
	padding: 8px 16px;
	min-height: 40px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	font-family: Rubik;
}

.primary {
	color: $neutral-100;
	text-align: center;
	font-family: Rubik;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 0.18px;
	background: $primary-500;
}
.secondary {
	color: #1e293b;
	text-align: center;
	font-family: Rubik;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: 0.18px;
	background: #eab308;
	&:hover {
		background-color: #fac515;
	}

	&.finished {
		border: 1px solid #eab308;
		color: #eab308;
		background-color: transparent;
		pointer-events: none;
	}
}

.smallest {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	padding: 2px 0;
	min-height: auto;
	line-height: 1.25;
}

.clear {
	background-color: transparent;
	color: $neutral-400;
}

.mr-0 {
	margin-right: 0 !important;
}

.ml-0 {
	margin-left: 0 !important;
}
</style>
